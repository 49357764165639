@import 'styles/mui';
@import 'styles/customize';
@import 'styles/layout';

body {
  font-family: Nunito, 'Segoe UI Variable', sans-serif !important;
  font-size: 0.875rem;
}

code,
pre,
input[type='code'],
textarea[type='code'] {
  font-family: 'Inconsolata', monospace !important;
}

* {
  outline: none !important;
}

a:hover {
  text-decoration: none !important;
}

@font-face {
  font-family: 'Segoe UI Variable';
  src: url('assets/fonts/Segoe-UI-Variable-Static-Display-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('assets/fonts/Segoe-UI-Variable-Static-Display-Semibold.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Segoe UI Variable';
  src: url('assets/fonts/Segoe-UI-Variable-Static-Display.ttf') format('truetype');
  font-weight: 400;
}

:root {
  --neutral-light-900: #091342;
  --neutral-light-800: #17204d;
  --neutral-light-700: #252e58;
  --neutral-light-600: #343c63;
  --neutral-light-500: #42496e;
  --neutral-light-400: #505779;
  --neutral-light-300: #5e6484;
  --neutral-light-200: #6b718c;
  --neutral-light-100: #7a7f9a;
  --neutral-light-90: #898ea4;
  --neutral-light-80: #979baf;
  --neutral-light-70: #a5a8ba;
  --neutral-light-60: #b3b6c5;
  --neutral-light-50: #c1c4d0;
  --neutral-light-40: #dfe0e6;
  --neutral-light-30: #ebecf0;
  --neutral-light-20: #f5f5f5;
  --neutral-light-10: #fafafa;
  --neutral-light-0: #ffffff;

  --red-color-500: #bf2600;
  --red-color-400: #de350b;
  --red-color-300: #ff5630;
  --red-color-200: #ff7452;
  --red-color-100: #ff8f73;
  --red-color-75: #ffbdad;
  --red-color-50: #ffebe6;

  --primary-color-main: #cfb675;
  --primary-color-dark: #b88914;
}
