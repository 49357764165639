.MuiFormControl-root {
  > label {
    margin-bottom: 4px;
    font-weight: 500;
    color: var(--neutral-light-500);
  }
  .MuiFormHelperText-root {
    margin: 0px;
    line-height: 20px;
    font-weight: 500;
  }
}

.MuiOutlinedInput-notchedOutline > legend {
  width: unset;
}

.MuiCalendarPicker-root {
  .PrivatePickersFadeTransitionGroup-root .MuiTypography-caption {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .PrivatePickersSlideTransition-root .MuiPickersDay-root {
    font-size: 0.75rem;
  }
}

.MuiDialogActions-root.MuiDialogActions-spacing {
  padding: 0px;
  justify-content: center;
}
